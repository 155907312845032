<template>
    <v-card flat>
        <v-tabs v-model="tab">
            <v-tab
                v-for="item in items"
                :key="item"
            >
                {{ item }}
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item key="Profile">
                <v-card flat>
                    <v-card-title>Profile</v-card-title>
                    <v-card-subtitle>Keep your contact details updated</v-card-subtitle>
                    <v-card-text>
                        <v-form ref="form" v-model="isValid" lazy-validation>
                            <v-row>
                                <v-col cols="12" md="6" v-for="(field, key) in fields" :key="key"> 
                                    <v-text-field
                                    v-if="field.type != 'select'"
                                    :label="field.label"
                                    :type="field.type"
                                    :rules="$rules[field.rule]"
                                    v-model="field.value"></v-text-field>
                                    <v-select
                                    v-if="field.type == 'select'"
                                    :label="field.label"
                                    :items="field.items"
                                    v-model="field.value"></v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" align="right">
                                    <v-btn color="primary" @click="updateProfile" :disabled="!isValid" :loading="loading">Update</v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item key="Picture">
                <v-card flat>
                    <v-card-title>Picture</v-card-title>
                    <v-card-subtitle>Modify your profile picture</v-card-subtitle>
                    <v-card-text>
                        <v-form ref="form3" v-model="isValidPic">
                            <v-img v-if="$store.getters.login.picture" :src="$store.getters.login.picture" class="imgProfile" @click="pic"></v-img>
                            <v-img v-else src="@/assets/profile.png" class="imgProfile" @click="pic"></v-img>
                            <input ref="camera" v-show="false" type="file" accept="image/*" @change="changePicture" capture="camera" id="camera" />
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item key="Password">
                <v-card flat>
                    <v-card-title>Password</v-card-title>
                    <v-card-subtitle>Modify your password</v-card-subtitle>
                    <v-card-text>
                        <v-form ref="form2" v-model="isValidPass">
                            <v-row>
                                <v-col cols="12" md="4"> 
                                    <v-text-field
                                    label="Current Password"
                                    type="password"
                                    :rules="$rules['required']"
                                    v-model="password.current"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4"> 
                                    <v-text-field
                                    label="New Password"
                                    type="password"
                                    :rules="$rules['password']"
                                    v-model="password.new"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4"> 
                                    <v-text-field
                                    label="Repeat New Password"
                                    type="password"
                                    :rules="$rules['password']"
                                    v-model="password.repeat"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" align="right">
                                    <v-btn color="primary" @click="changePassword" :disabled="!isValidPass" :loading="loading">Update</v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs-items>
    </v-card>
</template>

<style scope>
    .imgProfile {
        width: 200px;
        border-radius: 50%;
        cursor: pointer;
    }
</style>
<script>
  export default {
    data: () => ({
        tab: null,
        isValid: true,
        isValidPass: true,
        isValidPic: true,
        items: [ 'Profile', 'Picture', 'Password' ],
        loading: false,
        loadingPass: false,
        loadingPic: false,
        password: {
            current: '',
            new: '',
            repeat: '',
        },
        fields:{
            name: { value: '', label: 'Name', type: 'text', rule: 'required' },
            last_name: { value: '', label: 'Last Name', type: 'text', rule: 'required' },
            gender: { value: '', label: 'Gender', type: 'select', items: [{ text: 'Female', value: 'female' }, { text: 'Male', value: 'male', rule: 'required' }] },
            birthday: { value: '', label: 'Birthday', type: 'date', rule: 'required' },
            email: { value: '', label: 'Email', type: 'email', rule: 'requiredEmail' },
            phone: { value: '', label: 'Phone', type: 'tel', rule: 'phone' },
            mobile: { value: '', label: 'Mobile', type: 'tel', rule: 'required_phone' },
            street: { value: '', label: 'Street', type: 'text' },
            number: { value: '', label: 'Number', type: 'text' },
            city: { value: '', label: 'City', type: 'text', rule: 'required' },
            zip_code: { value: '', label: 'Zip Code', type: 'text', rule: 'required' },
            state: { value: '', label: 'State', type: 'select', rule: 'required',
                items: [
                    { value: "AL", text: "Alabama" },
                    { value: "AK", text: "Alaska" },
                    { value: "AZ", text: "Arizona" },
                    { value: "AR", text: "Arkansas" },
                    { value: "CA", text: "California" },
                    { value: "CO", text: "Colorado" },
                    { value: "CT", text: "Connecticut" },
                    { value: "DE", text: "Delaware" },
                    { value: "FL", text: "Florida" },
                    { value: "GA", text: "Georgia" },
                    { value: "HI", text: "Hawaii" },
                    { value: "ID", text: "Idaho" },
                    { value: "IL", text: "Illinois" },
                    { value: "IN", text: "Indiana" },
                    { value: "IA", text: "Iowa" },
                    { value: "KS", text: "Kansas" },
                    { value: "KY", text: "Kentucky" },
                    { value: "LA", text: "Louisiana" },
                    { value: "ME", text: "Maine" },
                    { value: "MD", text: "Maryland" },
                    { value: "MA", text: "Massachusetts" },
                    { value: "MI", text: "Michigan" },
                    { value: "MN", text: "Minnesota" },
                    { value: "MS", text: "Mississippi" },
                    { value: "MO", text: "Missouri" },
                    { value: "MT", text: "Montana" },
                    { value: "NE", text: "Nebraska" },
                    { value: "NV", text: "Nevada" },
                    { value: "NH", text: "New Hampshire" },
                    { value: "NJ", text: "New Jersey" },
                    { value: "NM", text: "New Mexico" },
                    { value: "NY", text: "New York" },
                    { value: "NC", text: "North Carolina" },
                    { value: "ND", text: "North Dakota" },
                    { value: "OH", text: "Ohio" },
                    { value: "OK", text: "Oklahoma" },
                    { value: "OR", text: "Oregon" },
                    { value: "PA", text: "Pennsylvania" },
                    { value: "RI", text: "Rhode Island" },
                    { value: "SC", text: "South Carolina" },
                    { value: "SD", text: "South Dakota" },
                    { value: "TN", text: "Tennessee" },
                    { value: "TX", text: "Texas" },
                    { value: "UT", text: "Utah" },
                    { value: "VT", text: "Vermont" },
                    { value: "VA", text: "Virginia" },
                    { value: "WA", text: "Washington" },
                    { value: "WV", text: "West Virginia" },
                    { value: "WI", text: "Wisconsin" },
                    { value: "WY", text: "Wyoming" },
                    { value: "DC", text: "District of Columbia" }
                ]
            },
        }
    }),
    methods: {
        loadData: function(){
            var este = this;
            este.loading = true;
            var data = {
                id: this.$store.getters.login.id,
                token: this.$store.getters.login.token,
            }
            this.axios.post(this.$url + 'profile', data).then(function(response){
                window.console.log(response);
                este.fields.name.value = response.data.name;
                este.fields.last_name.value = response.data.last_name;
                este.fields.gender.value = response.data.gender;
                este.fields.birthday.value = response.data.birthday;
                este.fields.email.value = response.data.email;
                este.fields.phone.value = response.data.phone;
                este.fields.mobile.value = response.data.mobile;
                este.fields.street.value = response.data.street;
                este.fields.number.value = response.data.number;
                este.fields.city.value = response.data.city;
                este.fields.zip_code.value = response.data.zip_code;
                este.fields.state.value = response.data.state;
                este.loading = false;
            }).catch(function(err){
                este.loading = false;
                if(typeof(err.response) !== 'undefined' && err.response.status == 401){
                    este.$store.commit('logout');
                }
                var errMsg;
                if(typeof(err.response) !== 'undefined'){
                    errMsg = err.response.data.msg;
                } else {
                    errMsg = 'There was an error. Please try again.';
                }
                var mensaje = {
                    "color": "red",
                    "timeout": 5000,
                    "message": errMsg
                }
                este.$store.commit("msgMuestra", mensaje);
            });
        },
        updateProfile: function(){
            window.console.log('ENTRO');
            var este = this;
            este.loading = true;
            var data = {
                id: este.$store.getters.login.id,
                token: este.$store.getters.login.token,
                name: este.fields.name.value,
                last_name: este.fields.last_name.value,
                gender: este.fields.gender.value,
                birthday: este.fields.birthday.value,
                email: este.fields.email.value,
                phone: este.fields.phone.value,
                mobile: este.fields.mobile.value,
                street: este.fields.street.value,
                number: este.fields.number.value,
                city: este.fields.city.value,
                zip_code: este.fields.zip_code.value,
                state: este.fields.state.value,
            }
            this.axios.post(this.$url + 'profileUpdate', data).then(function(response){
                este.loading = false;
                este.$store.commit('loginSet', data);
                var mensaje = {
                    "color": "green",
                    "timeout": 5000,
                    "message": response.data.msg
                }
                este.$store.commit("msgMuestra", mensaje);
            }).catch(function(err){
                este.loading = false;
                window.console.log(err);
                if(typeof(err.response) !== 'undefined' && err.response.status == 401){
                    este.$store.commit('logout');
                }
                var errMsg;
                if(typeof(err.response) !== 'undefined'){
                    errMsg = err.response.data.msg;
                } else {
                    errMsg = 'There was an error. Please try again.';
                }
                var mensaje = {
                    "color": "red",
                    "timeout": 5000,
                    "message": errMsg
                }
                este.$store.commit("msgMuestra", mensaje);
            });
        },
        changePassword: function(){
            let este = this;
            let mensaje;
            if(este.password.current == este.password.new){
                mensaje = {
                    "color": "red",
                    "timeout": 5000,
                    "message": "Current Password and New Password cannot be the same"
                }
                este.password.new = '';
                este.password.repeat = '';
                este.$store.commit("msgMuestra", mensaje);
            } else if(este.password.new != este.password.repeat){
                mensaje = {
                    "color": "red",
                    "timeout": 5000,
                    "message": "New Password and Repeat New Password do not match"
                }
                este.password.new = '';
                este.password.repeat = '';
                este.$store.commit("msgMuestra", mensaje);
            } else {
                let data = {
                    id: este.$store.getters.login.id,
                    token: este.$store.getters.login.token,
                    current: este.password.current,
                    new: este.password.new,
                    repeat: este.password.repeat,
                }
                este.loadingPass = true;
                este.axios.post(este.$url + 'changePassword', data).then(function(response){
                    este.loadingPass = false;
                    let mensaje = {
                        "color": "green",
                        "timeout": 5000,
                        "message": response.data.msg
                    }
                    este.$store.commit("msgMuestra", mensaje);
                }).catch(function(err){
                    este.loadingPass = false;
                    window.console.log(err);
                    if(typeof(err.response) !== 'undefined' && err.response.status == 401){
                        este.$store.commit('logout');
                    }
                    let errMsg;
                    if(typeof(err.response) !== 'undefined'){
                        errMsg = err.response.data.msg;
                    } else {
                        errMsg = 'There was an error. Please try again.';
                    }
                    let mensaje = {
                        "color": "red",
                        "timeout": 5000,
                        "message": errMsg
                    }
                    este.$store.commit("msgMuestra", mensaje);
                });
            }
        },
        pic: function(){
            this.$refs.camera.click();
        },
        changePicture: function(){
            var este = this;
            este.loadingPic = true;
            var file = document.querySelector('input[type=file]').files[0];
            var reader = new FileReader();
            reader.onload = function(e) {
                window.console.log(e.target.result);             
                let image = e.target.result;
                let datos = {
                    id: este.$store.getters.login.id,
                    token: este.$store.getters.login.token,
                    image: image,
                };
                este.axios.post(este.$url + 'changePicture', datos).then(function(response){
                    este.loadingPic = false;
                    este.$store.commit('setPicture', image);
                    let mensaje = {
                        "color": "green",
                        "timeout": 5000,
                        "message": response.data.msg
                    }
                    este.$store.commit("msgMuestra", mensaje);
                }).catch(function(err){
                    este.loadingPic = false;
                    window.console.log(err);
                    if(typeof(err.response) !== 'undefined' && err.response.status == 401){
                        este.$store.commit('logout');
                    }
                    let errMsg;
                    if(typeof(err.response) !== 'undefined'){
                        errMsg = err.response.data.msg;
                    } else {
                        errMsg = 'There was an error. Please try again.';
                    }
                    let mensaje = {
                        "color": "red",
                        "timeout": 5000,
                        "message": errMsg
                    }
                    este.$store.commit("msgMuestra", mensaje);
                });
            };
            reader.onerror = function(error) {
                var mensaje = {
                    "color": "red",
                    "timeout": 5000,
                    "message": "There was an error. Please try again."
                }
                este.loading5 = false;
                este.$store.commit("msgMuestra", mensaje);
                window.console.log(error);
            };
            reader.readAsDataURL(file);     
        },

    },
    mounted: function(){
        this.loadData();
    }
  }
</script>
